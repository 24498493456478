<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <!-- <el-form-item label='省:'>
                        <el-select 
                        v-model="filters.provinceID" 
                        placeholder="请选择" 
                        @change='selProvince' 
                        v-loading='provinceLoading'
                        :clearable="userInfoByToken.ProvinceID == null ? true : false ">
                            <el-option
                            v-for="item in provinceOptions"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                            :disabled='provinceIsDisabled'>
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label='市:'>
                        <el-select 
                        v-model="filters.cityID" 
                        placeholder="请选择" 
                        @change='selCity' 
                        v-loading='cityLoading'
                        :clearable="userInfoByToken.CityID == null ? true : false">
                            <el-option
                            v-for="item in cityOptions"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                            :disabled='cityIsDisabled'>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='区/县:'>
                    <el-select 
                    v-model="filters.regionID" 
                    placeholder="请选择" 
                    v-loading='areaLoading'
                    :clearable="userInfoByToken.RegionID == null ? true : false">
                            <el-option
                            v-for="item in areaOptions"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                            :disabled='areaIsDisabled'>
                            </el-option>
                        </el-select></el-form-item> -->
                    <el-form-item label="地区:">
                        <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        highlight-current-row
        @current-change='selCurrentRow'
        v-loading='listLoading'
        :data="tableData"
        :cell-style='rowStyle'
        :header-cell-style="headerStyle"
        style="width: 95%">
            <el-table-column type='index' label="序号" width='60'></el-table-column>
            <!-- <el-table-column prop="Province" label="省"></el-table-column> -->
            <el-table-column prop="City" label="市"></el-table-column>
            <el-table-column prop="Region" label="区/县"></el-table-column>
            <el-table-column prop="LearningCount" label="学习中"></el-table-column>
            <el-table-column prop="ExamingCount" label="待考试"></el-table-column>
            <el-table-column prop="CertificatingCount" label="待发证"></el-table-column>
            <el-table-column prop="CertificatedCount" label="已发证"></el-table-column>
            <el-table-column prop="" label="总人数">
                <template slot-scope='scope'>
                    {{scope.row.LearningCount + scope.row.ExamingCount + scope.row.CertificatingCount + scope.row.CertificatedCount}}
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import { getAreaDictionaryList,getStudentExamineeAggregateListPage,getUserByToken } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            provinceOptions:[],
            cityOptions:[],
            areaOptions:[],
            filters:{
                provinceID: 1919,
                cityID:'',
                regionID:'',
                selecAreaArr: [],
            },
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            tableData:[],
            listLoading:false,
            currentRow:null,
            token:'',
            // isDisabled:false,
            provinceLoading:false,
            cityLoading:false,
            areaLoading:false,
            provinceIsDisabled:false,
            cityIsDisabled:false,
            areaIsDisabled:false,
            userInfoByToken:{},
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取行政区信息
        getPlace(params){
            switch(params.level){
                case 0:
                    this.provinceLoading = true
                    break;
                    
                case 1:
                    this.cityLoading = true
                    break;
                case 2:
                    this.areaLoading = true
                    break;
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    switch(params.level){
                        case 0:
                            this.provinceLoading = false
                            this.provinceOptions = res.data.Response.reverse();
                            break;
                        case 1:
                            this.cityLoading = false
                            this.cityOptions = res.data.Response.reverse();
                            break;
                        case 2:
                            this.areaLoading = false
                            this.areaOptions = res.data.Response.reverse();
                            break
                    }
                }else{return}
            }).catch(() => {})
        },
        // 选择省，获取市
        selProvince(value){
            if(!value){
                this.cityOptions = []
                this.areaOptions = []
                this.filters.cityID = ''
                this.filters.regionID = ''
            }else{
                var params = {level:1,parentId:value}
                this.getPlace(params)
            }           
        },
        // 选择市，获取区/县
        selCity(value){
            if(!value){
                this.areaOptions = []
                this.filters.regionID = ''
            }else{
                var params = {level:2,parentId:value}
                this.getPlace(params)
            }         
        },
        // 获取列表当前行数据
        selCurrentRow(value){
            this.currentRow = value
        },
        // 列表文字居中
        rowStyle(){
            return 'text-align:center;'
        },
        headerStyle(){
            return 'text-align:center;'
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value         
            this.getData()
        },
        handleCurrentChange(value){
            // if(this.filters.provinceID != null || this.filters.cityID != null || this.filters.regionID != null){
            //     this.pages.pageIndex = 1
            // }else{
                this.pages.pageIndex = value
            // }                
            this.getData()
        },
        // 查询
        getStudentExamineeAggregateListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 获取列表数据
        getData(){
            this.listLoading = true
            var params = {
                provinceID: 1919,
                // cityID:this.filters.cityID ? this.filters.cityID : '',
                // regionID:this.filters.regionID ? this.filters.regionID : '',
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            if(this.filters.selecAreaArr.length > 0) {
                params.cityID = this.filters.selecAreaArr[0]
            }
            if(this.filters.selecAreaArr.length > 1) {
                params.regionID = this.filters.selecAreaArr[1]
            }
            getStudentExamineeAggregateListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.erroe(res.data.Message)
                }
            }).catch(() => {})
        },
        // 得到用户的省市区信息
        getUserInfo(){
            // var token = localStorage.getItem("Token")
            // getUserByToken({token:token}).then(res => {
            //     if(res.Success){
                    var data = JSON.parse(localStorage.getItem("user"))
                    this.userInfoByToken = data  
                    if(data.ProvinceID == null){
                        this.provinceOptions = []
                        this.filters.provinceID = ''
                    }else{
                        var params = {
                            parentId:data.ProvinceID,
                            level:1
                        }
                        this.getPlace(params)
                        this.provinceIsDisabled = true
                    }  
                    if(data.CityID == null){
                        this.cityOptions = []
                        this.filters.cityID = ''
                    }else{
                        var params = {
                            parentId:data.CityID,
                            level:2
                        }
                        this.getPlace(params)
                        this.cityIsDisabled = true
                    }
                    if(data.RegionID == null){
                        this.areaOptions = []
                        this.filters.regionID = ''
                    }else{
                        this.areaIsDisabled = true
                    }                               
                    this.filters.provinceID = data.ProvinceID
                    this.filters.cityID = data.CityID
                    this.filters.regionID = data.RegionID
                    this.getData()
            //     }else{return}
            // }).catch(() =>{})
        },
    },
    mounted(){
        // var params = {level:0}
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getPlace(params)
        // this.getData()
        this.getUserInfo()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>